<template>
  <nav class="d-flex justify-content-center mb-2">
    <ul class="pagination my-2">
      <li
        v-if="pageMin != 1"
        @click="changePage(1)"
        class="page-item"
      >
        <a class="page-link page-link-icon" href="#top">
          <span aria-hidden="true">
            <i class="bi bi-chevron-left"></i>
          </span>
        </a>
      </li>

      <li
        v-for="page in pagesRange"
        :key="page"
        @click.stop="changePage(page)"
        :class="['page-item', page == current ? 'active' : '']"
      >
        <a class="page-link" href="#top">
          {{ page }}
          </a>
      </li>
      
      <li
        v-if="pageMax != total"
        @click="changePage(total)"
        class="page-item"
      >
        <a class="page-link page-link-icon" href="#top">
          <span aria-hidden="true">
            <i class="bi bi-chevron-right"></i>
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  emits: ['changePage'],

  props: {
    total: Number,
    current: Number,
  },

  data: () => ({
    viewRange: 2,
  }),

  computed: {
    pageMin() {
      // Минимальный номер отображаемой страницы
      let min = this.current - this.viewRange;
      return (min < 1) ? 1 : min;
    },

    pageMax() {
      // Максимальный номер отображаемой страницы
      let max = this.current + this.viewRange;
      return (max > this.total) ? this.total : max;
    },

    pagesRange() {
      // Диапазон отображаемых страниц
      let range = []
      for (let p = this.pageMin; p <= this.pageMax; p++) {
        range.push(p);
      }
      return range;
    },
  },

  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
  }
}
</script>

<style scoped>
.bi::before {
  vertical-align: -0.175em;
}

.page-link-icon {
  padding: 6px 8px;
}
</style>
