<template id="top">
  <pre-page-loader
    v-if="loading"
  />

  <main-page
    v-else
    :products="products"
    :updated="updated"
    class="fade-in"
  />
</template>

<script>
import axios from 'axios';

import '@/assets/css/main.css';

import PrePageLoader from '@/pages/PrePageLoader';
import MainPage from '@/pages/MainPage';

export default {
  name: 'App',
  components: {
    PrePageLoader,
    MainPage,
  },

  data: () => ({
    loading: true,
    products: [],
    updated: null,
  }),

  async mounted() {
    try {
      let response = await axios.get('/api/items');
      // let response = await axios.get('http://127.0.0.1:5001/items');
      this.products = response.data.items;
      this.updated = response.data.updated;
      setTimeout(() => this.loading = false, 1000);
    }
    catch(e) {
      console.log(e);
    }
  },
}
</script>

<style>
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css');

.fade-in {
  opacity: 0;
  animation: fade_in .3s forwards;
}

@keyframes fade_in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>
