<template>
  <div class="row g-3 mb-2">
    <transition-group name="fade" appear>
      <product-item
        v-for="product in products"
        :key="product.id"
        :product="product"
      />
    </transition-group>
  </div>
</template>

<script>
import ProductItem from '@/components/ProductItem';

export default {
  name: 'ProductList',
  props: {
    products: Array,
  },

  components: {
    ProductItem,
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>