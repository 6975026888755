<template>
  <select
    :value="modelValue"
    @input="updateSelect"
    class="form-select"
  >
    <option
      v-for="item in items"
      :key="item.value"
      :value="item.value"
    >
      {{ item.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'BSelect',
  
  props: {
    modelValue: [String, Number],
    items: Array,
  },

  methods: {
    updateSelect(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
}
</script>
