<template>
  <div class="col-12 col-sm-6 col-lg-4 col-xl-3 text-decoration-none" >
    <a
      :href="`/item/${product.id}`"
      target="_blank"
      class="card text-decoration-none p-1 mb-2"
      style="height: 100%;"
    >
      <img :src="product.image" class="card-img-top">
      <div class="card-body pb-0">
        <p class="card-text">{{ product.name }}</p>
        <h6 class="card-title text-secondary mb-0"><s>${{ product.price }}</s></h6>
        <h5 class="card-title mb-0">${{ product.final_price }}</h5>
      </div>
      <div class="card-footer bg-transparent pt-0">
        <p class="card-text mb-1">
          <small class="text-body-secondary">
            Last seen {{ updated }}
          </small>
        </p>
        <span class="badge rounded-pill text-bg-primary">
          <i class="bi bi-cart icon"></i>
          <span class="ml-2">{{ product.sold }}</span>
        </span>
        <span class="badge rounded-pill text-bg-primary">
          <i class="bi bi-truck icon"></i>
          <span class="ml-2">{{ shipping }}</span>
        </span>
        <span class="badge rounded-pill text-bg-primary">
          <i class="bi bi-eye icon"></i>
          <span class="ml-2">{{ product.views }}</span>
        </span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ProductItem',
  props: {
    product: Object,
  },

  computed: {
    shipping() {
      return this.product.shipping ? 'Free' : 'Not free';
    },

    updated() {
      let date = new Date(this.product.last_seen * 1000);
      let day = date.getDate();
      let mon = date.toLocaleString('en', { month: 'short' });
      let hrs = date.getHours();
      let min = ('0' + date.getMinutes()).slice(-2);
      let text = `${day} ${mon} at ${hrs}:${min}`;
      return text;
    },
  }
}
</script>

<style scoped>
.icon {
  margin-right: 4px;
}

.badge {
  margin-right: 4px;
}

.card-footer {
  border: none;
}
</style>
