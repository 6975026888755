<template>
  <input
    type="text"
    class="form-control"
    :value="modelValue"
    @input="updateInput"
    :placeholder="placeholder"
    :autofocus="autofocus"
  >
</template>

<script>
export default {
  name: 'BInput',
  
  props: {
    modelValue: [String, Number],
    placeholder: String,
    autofocus: Boolean,
  },
  
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
}
</script>
