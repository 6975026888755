<template>
  <div class="container mt-3 mb-2">
    <h3 class="text-center mb-2">Banggood Free Gifts</h3>
    <div class="text-center" v-html="updatedInfo"></div>
    
    <div class="mt-2 mb-2 row g-2">
      <div class="col-12 col-sm-6 col-lg-3">
        <b-input
          v-model="search"
          placeholder="Search"
        />
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <b-select
          v-model="type"
          :items="typeItems"
        />
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <b-select
          v-model="sort"
          :items="sortItems"
        />
      </div>
      <div class="col-12 col-sm-6 col-lg-3">
        <b-select
          v-model="shipping"
          :items="shippingItems"
        />
      </div>
    </div>
    
    <pagination
      :total="totalPages"
      :current="page"
      @changePage="changePage"
    />

    <product-list
      :products="devidedProducts"
    />

    <pagination
      :total="totalPages"
      :current="page"
      @changePage="changePage"
    />
  </div>
</template>

<script>
import BInput from '@/components/BInput';
import BSelect from '@/components/BSelect';
import Pagination from '@/components/Pagination';
import ProductList from '@/components/ProductList';

import PrePageLoader from '@/pages/PrePageLoader';

export default {
  name: 'MainPage',
  components: {
    BInput,
    BSelect,
    Pagination,
    ProductList,
    PrePageLoader,
  },

  props: {
    products: Array,
    updated: Number,
  },

  data: () => ({
    // products: [],
    // updated: null,

    page: 1,
    perPage: 20,

    type: 'actual',
    typeItems: [
      { name: 'Only last update', value: 'actual', },
      { name: 'All items', value: 'all', },
    ],

    search: '',

    sort: 'price-asc',
    sortItems: [
      { name: 'By price Az', value: 'price-asc', },
      { name: 'By price zA', value: 'price-desc', },
      { name: 'By final price Az', value: 'final_price-asc', },
      { name: 'By final price zA', value: 'final_price-desc', },
      { name: 'By sold Az', value: 'sold-asc', },
      { name: 'By sold zA', value: 'sold-desc', },
      { name: 'By views Az', value: 'views-asc', },
      { name: 'By views zA', value: 'views-desc', },
    ],

    shipping: 'all',
    shippingItems: [
      { name: 'All items', value: 'all', },
      { name: 'With free shipping', value: 'free', },
      { name: 'Without free shipping', value: 'not_free', },
    ],
  }),

  computed: {
    totalProducts() {
      return this.products.length;
    },

    totalActualProducts() {
      if (!this.updated) return 0;
      return [...this.products].filter(p => p.last_seen == this.updated).length;
    },

    updatedText() {
      if (!this.updated) return null;
      let date = new Date(this.updated * 1000);
      let day = date.getDate();
      let mon = date.toLocaleString('en', { month: 'short' });
      let hrs = date.getHours();
      let min = ('0' + date.getMinutes()).slice(-2);
      let text = `${day} ${mon} at ${hrs}:${min}`;
      return text;
    },

    updatedInfo() {
      return `Updated <b>${this.updatedText}</b>: ` 
        + `${this.totalActualProducts} items (${this.totalProducts} total)`;
    },

    taggedProducts() {
      // Create _tags key for searching
      let products = [...this.products];
      let fields = ['id', 'name', 'price', 'last_seen', 'views'];

      products.forEach(product => {
        let tags = Object.keys(product).reduce((tags, key) => {
          if (fields.includes(key)) {
            tags.push(`${key}:${product[key]}`);
          }
          return tags;
        }, []);

        product._tags = tags.join(' ');
      });

      return products;
    },

    typedProducts() {
      // Filter by type: actual or all products
      if (this.type === 'actual') {
        return [...this.taggedProducts].filter(p => p.last_seen == this.updated);
      }
      return [...this.taggedProducts];
    },

    searchedProducts() {
      // Search by _tags field
      let queryList = this.search.toLowerCase().split(' ')
        .filter(q => q.trim() != '');

      let res = [...this.typedProducts];
      queryList.forEach(query => {
        res = res.filter(item => item._tags.toLowerCase().indexOf(query) > -1);
      });

      return res;
    },

    sortedProducts() {
      // Sort by price or views and desc or asc
      let [field, type] = this.sort.split('-');
      let result = [...this.searchedProducts]
        .sort((p1, p2) => p1[field] - p2[field]);
      return type == 'desc' ? result : result.reverse();
    },

    shippedProducts() {
      if (this.shipping === 'free') {
        return [...this.sortedProducts].filter(p => p.is_free_shipping === true);
      }
      else if (this.shipping === 'not_free') {
        return [...this.sortedProducts].filter(p => p.is_free_shipping === false);
      }
      return [...this.sortedProducts];
    },

    totalPages() {
      // Calculate total pages count
      return Math.ceil(this.shippedProducts.length / this.perPage);
    },

    devidedProducts() {
      // Show only for current page
      let start = (this.page - 1) * this.perPage;
      let finish = start + this.perPage;
      return [...this.shippedProducts].slice(start, finish);
    },
  },

  watch: {
    search() {
      this.page = 1;
    },

    type() {
      this.page = 1;
    },

    sort() {
      this.page = 1;
    },

    shipping() {
      this.page = 1;
    },
  },

  methods: {
    changePage(page) {
      this.page = page;
    },
  },
}
</script>
